import React from 'react';

// Styles
import "../../styles/components/Howget.css";
import "../../styles/utilities/container.css";

// Images
import debitcard from "../../assets/debit-card.png";
import group from "../../assets/group.png";
import passport from "../../assets/passport.png";


function HowGet() {
    return (
        <div className="howget">
            <div className="howget__container container">
                <div className="howget__inner">
                <h2 className="howget__title">
                    Як отримати кредит?
                </h2>
                    <div className="howget__cards">
                        <ul className="howget__cards-list">
                            <li className="howget__cards-item">
                                <div className="howget__card">
                                    <div className="howget__cards-icon">
                                        <img className="howget__cards-image" src={group} alt="" width="40" height="40"/>
                                    </div>
                                    <div className="howget__cards-text">
                                        <h2 className="howget__cards-title">
                                            Від 18 років.
                                        </h2>
                                        <p className="howget__card-description">
                                            Кредит доступний тільки повнолітнім
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="howget__cards-item">
                                <div className="howget__card">
                                    <div className="howget__cards-icon">
                                        <img className="howget__cards-image" src={passport} alt="" width="40"
                                             height="40"/>
                                    </div>
                                    <div className="howget__cards-text">
                                    <h2 className="howget__cards-title">
                                            Наявність паспорта України.
                                        </h2>
                                        <p className="howget__card-description">
                                            Кредит видається тільки громадянинам України
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="howget__cards-item">
                                <div className="howget__card">
                                    <div className="howget__cards-icon">
                                        <img className="howget__cards-image" src={debitcard} alt="" width="40"
                                             height="40"/>
                                    </div>
                                    <div className="howget__cards-text">
                                    <h2 className="howget__cards-title">
                                            Наявність банківської картки.
                                        </h2>
                                        <p className="howget__card-description">
                                            Картка може бути будь-якого українського банку
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <button className="howget__card-button button-primary">Подати заявку</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HowGet;