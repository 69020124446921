import React, {useEffect, useRef, useState} from "react";

// Styles
import "../../styles/components/Form.css";

function Form({ isFormVisible, setIsFormVisible}) {
    const [formData, setFormData] = useState({
        last_name: "",
        first_name: "",
        surname: "",
        gender: "",
        phone_number: "",
        email: "",
        confirmation: "",
        call_confirmation: "",
        terms: false
    });

    const [isSubmitted, setIsSubmitted] = useState(false);

    const formRef = useRef(null);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value
        });
    };

    const isFormComplete = () => {
        const { last_name, first_name, surname, gender, phone_number, email, call_confirmation, terms } = formData;
        return (
            last_name &&
            first_name &&
            surname &&
            gender &&
            phone_number &&
            email &&
            call_confirmation &&
            terms
        );
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormComplete()) {
            setIsSubmitted(true);
        }
    };

    const handleClose = () => {
        setIsFormVisible(!isFormVisible);
    };

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (formRef.current && !formRef.current.contains(e.target)) {
                setIsFormVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setIsFormVisible]);

    useEffect(() => {
        if (isFormVisible) {
            document.body.style.overflow = "hidden";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isFormVisible]);

    return (
        <div className="form">
            <div className="form-container" ref={formRef} style={isSubmitted ? {width: "auto", height: "auto"} : null}>
                <div className="form-title">
                    Заявка на позику
                    <button className="form-close-button" onClick={handleClose}>✖</button>
                </div>
                <div className="form-content-name">
                    {isSubmitted ? (
                        <div className="form-success-message">
                            Ваша заявка була успішно відправлена!
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <h2 className="form-content-name__title">
                                Вкажіть ПІБ:
                            </h2>
                            <div className="form-content__personal-info">
                                <div className="form-content__info">
                                    <label htmlFor="last_name">Прізвище <span>*</span></label>
                                    <input type="text" name="last_name" id="last_name" placeholder="Введіть прізвище" value={formData.last_name} onChange={handleChange} />
                                </div>
                                <div className="form-content__info">
                                    <label htmlFor="first_name">Ім'я <span>*</span></label>
                                    <input type="text" name="first_name" id="first_name" placeholder="Введіть ім'я" value={formData.first_name} onChange={handleChange} />
                                </div>
                                <div className="form-content__info">
                                    <label htmlFor="surname">По батькові <span>*</span></label>
                                    <input type="text" name="surname" id="surname" placeholder="Введіть по батькові" value={formData.surname} onChange={handleChange} />
                                </div>
                            </div>
                            <h2 className="form-content-name__title">Вкажіть стать:</h2>
                            <div className="form-content__gender-chooser">
                                <div className="form-content__chooser-container">
                                    <label className="heading">Cтать <span>*</span></label>
                                    <div className="form-content__chooser-buttons">
                                        <div className="form-content__male">
                                            <input type="radio" name="gender" id="male" className="custom-radio" value="male" checked={formData.gender === "male"} onChange={handleChange} />
                                            <label htmlFor="male" className="custom-label">Чоловік</label>
                                        </div>
                                        <div className="form-content__female">
                                            <input type="radio" name="gender" id="female" className="custom-radio" value="female" checked={formData.gender === "female"} onChange={handleChange} />
                                            <label htmlFor="female" className="custom-label">Жіночий</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="form-content-name__title">Вкажіть контактні дані:</h2>
                            <div className="form-content__personal-info">
                                <div className="form-content__info">
                                    <label htmlFor="phone_number">Номер телефону <span>*</span></label>
                                    <input type="text" name="phone_number" id="phone_number" placeholder="Введіть номер телефону" value={formData.phone_number} onChange={handleChange} />
                                </div>
                                <div className="form-content__info">
                                    <label htmlFor="email">Email <span>*</span></label>
                                    <input type="email" name="email" id="email" placeholder="Введіть email" value={formData.email} onChange={handleChange} />
                                </div>
                                <div className="form-content__info">
                                    <label htmlFor="confirmation">Підтвердження телефону</label>
                                    <input type="text" name="confirmation" id="confirmation" placeholder="Підтвердити телефон" value={formData.confirmation} onChange={handleChange} disabled />
                                </div>
                                <div className="form-content__info">
                                    <label htmlFor="call_confirmation">Останні 4 цифри з дзвінка</label>
                                    <input type="text" name="call_confirmation" id="call_confirmation" placeholder="Введіть код" value={formData.call_confirmation} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="form-content__actions">
                                <div className="form-content__checkbox">
                                    <input type="checkbox" id="terms" name="terms" checked={formData.terms} onChange={handleChange} />
                                    <label htmlFor="terms">Прийняти умови документів</label>
                                </div>
                                <button type="submit" className={`form-content__submit ${isFormComplete() ? 'active' : ''}`} disabled={!isFormComplete()}>
                                    ПРОДОВЖИТИ <span className="arrow">→</span>
                                </button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Form;
