import React from "react";

//Styles
import "../../styles/components/Credit.css";

import online from "../../assets/online.webp";

function Credit() {
  return (
    <div className="credit-section">
        <div className="credit-section__inner container">
            <div className="credit-section__text">
                <h2 className="credit-section__title">Кредит онлайн на картку в Україні</h2>
                <p className="credit-section__description">В Україні щорічно ринок мікрокредитів зростає на 85-90%. Зареєстровано 700 мікрофінансових
                    організацій, які пропонують миттєво оформити кредит на картку. FastLoan – український сервіс,
                    який дозволяє взяти позику на суму до 15 000 гривень лише за декілька хвилин. Більше ніяких довгих
                    черг: зайшли в особистий кабінет, подали заявку та отримали гроші.</p>
            </div>
            <div className="credit-section__image">
                <img className="credit-section__img" src={online} width="500" height="500" alt={"credit"}/>
            </div>
        </div>
    </div>
  );
}

export default Credit;