import React, { useState } from "react";

//Style
import "../../styles/components/Calculation.css";

// Icons
import { FaRegCircleCheck } from "react-icons/fa6";

// Components
import Form from "../common/Form";

function Calculation() {
    const [amount, setAmount] = useState(10000);
    const [days, setDays] = useState(30);
    const [total, setTotal] = useState(10000);
    const [dueDate, setDueDate] = useState('10.07.2024');
    const [isFormVisible, setIsFormVisible] = useState(false);

    const handleAmountChange = (e) => {
        const newAmount = Math.min(Math.max(parseInt(e.target.value), 3000), 30000);
        setAmount(newAmount);
        calculateTotal(newAmount, days);
    };

    const handleDaysChange = (e) => {
        const newDays = Math.min(Math.max(parseInt(e.target.value), 1), 30);
        setDays(newDays);
        calculateTotal(amount, newDays);
    };

    const calculateTotal = (amount, days) => {
        let totalAmount = parseInt(amount);
        if (days > 10) {
            const weeks = Math.floor((days - 1) / 7);
            totalAmount += (totalAmount * 0.02 * weeks); // Increase by 2% per week
        }
        setTotal(Math.round(totalAmount)); // Ensure the result is always an integer

        const dueDate = new Date();
        dueDate.setDate(dueDate.getDate() + days);
        setDueDate(dueDate.toLocaleDateString('ru-RU'));
    };

    return (
        <>
        <div className="calculation__section">
            <div className="calculation__container container">
                <div className="calculation__header">
                    <h1 className="calculation__title">Кредит онлайн</h1>
                    <p className="calculation__description">Позика на карту без вихідних, 24/7</p>
                    <ul className="calculator__list">
                        <li className="calculator__item"><FaRegCircleCheck /> Заповніть уважно заявку</li>
                        <li className="calculator__item"><FaRegCircleCheck /> Отримайте схвалення запиту</li>
                        <li className="calculator__item"><FaRegCircleCheck /> Отримайте гроші на Вашу банківську картку</li>
                    </ul>
                </div>
                <div className="calculator-block">
                    <div className="calculator-block__calculator">
                        <div className="calculator-block__container">
                            <div className="calculator-block__form">
                                <div className="calculator-block__range-block">
                                    <div>
                                        <div className="calculator_block__range-info">
                                            <label>Оберіть суму</label>
                                            <div className="calculator-block__buttons">
                                                <button
                                                    onClick={() => handleAmountChange({target: {value: amount - 1000}})}
                                                    disabled={amount <= 3000}
                                                >-</button>
                                                <span>{amount.toLocaleString()} ₴</span>
                                                <button
                                                    onClick={() => handleAmountChange({target: {value: amount + 1000}})}
                                                    disabled={amount >= 30000}
                                                >+</button>
                                            </div>
                                        </div>
                                        <input type="range" min="3000" max="30000" step="1000" value={amount}
                                               onChange={handleAmountChange}/>
                                    </div>
                                    <div>
                                        <div className="calculator_block__range-info">
                                            <label>Оберіть термін</label>
                                            <div className="calculator-block__buttons">
                                                <button
                                                    onClick={() => handleDaysChange({target: {value: days - 1}})}
                                                    disabled={days <= 1}
                                                >-</button>
                                                <span>{days} днів</span>
                                                <button
                                                    onClick={() => handleDaysChange({target: {value: days + 1}})}
                                                    disabled={days >= 30}
                                                >+</button>
                                            </div>
                                        </div>
                                        <input type="range" min="1" max="30" value={days} onChange={handleDaysChange}/>
                                    </div>
                                </div>
                                <div className="calculator-block__total-block">
                                    <div className="calculator-block__total-text">
                                        Ви повертаєте: <strong>{total.toLocaleString()} ₴</strong>
                                    </div>
                                    <div className="calculator-block__total-text">
                                        До: <strong>{dueDate}</strong>
                                    </div>
                                </div>
                                <div className="calculator-block__button">
                                    <button className="button-primary" onClick={() => setIsFormVisible(true)}>Отримати гроші</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="calculator-block__notice">
                        Повторним клієнтам до <b>100 000 ₴</b>
                    </div>
                </div>
            </div>
        </div>
            {isFormVisible ? <Form isFormVisible={isFormVisible} setIsFormVisible={setIsFormVisible} /> : null}
        </>
    );
}

export default Calculation;