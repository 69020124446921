import React, { useState } from 'react';
import "../../styles/components/Faq.css";

const FAQ = ({ question, answer, isOpen, onClick }) => {
    return (
        <div className="faq-item">
            <div className="faq-question" onClick={onClick}>
                <h4>{question}</h4>
                <span>{isOpen ? <span>&#10006;</span> : '+'}</span>
            </div>
            {isOpen && <div className="faq-answer">{answer}</div>}
        </div>
    );
};

const FAQList = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const faqs = [
        {
            question: 'Які максимальні сума та термін кредитування?',
            answer: `Сервіс онлайн-кредитування дозволяє отримати на Вашу банківську картку суму від 500 до 15000
гривень, строком від 3 до 30 днів. За допомогою калькулятора кредиту на сайті (https://fastloan.live/)
можна дізнатися всі необхідні платежі за обраними умовами кредиту.`
        },
        {
            question: 'Як можна погасити кредит?',
            answer: `Повернути кредит можна декількома способами:
Банківська картка.
Для того, щоб повернути позикові кошти даним способом, потрібно зайти до Особистого Кабінету до
розділу «Кредити», натиснути на кнопку «Погасити кредит» і вибрати спосіб «Банківська картка».
Якщо ж на Вашій основній картці недостатньо коштів, просто додайте ще одну картку, з якої зможете
здійснити платіж.
Каса банку.
Ви можете оплатити послуги користування кредитом в будь-якому відділенні банку на території
України. Банківські реквізити компанії можно дізнатись у онлайн у менеджера.`
        },
        {
            question: 'Скільки кредитів можна взяти одночасно?',
            answer: `Ви можете користуватися одночасно лише одним кредитом в сервісі FastLoan. Якщо ж Вам
необхідний ще один кредит - Ви можете повернути поточний кредит та взяти новий, або ж оформити
послугу пролонгації.`
        }
    ];

    return (
        <div className="faq-container container">
            <h2 className="faq-title">Часто задавані питання</h2>
            {faqs.map((faq, index) => (
                <FAQ
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                    isOpen={openIndex === index}
                    onClick={() => toggleFAQ(index)}
                />
            ))}
        </div>
    );
};

export default FAQList;
