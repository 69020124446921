import React from "react";

//Styles
import "../../styles/components/Advantages.css";


//Icons
import {MdOutlineNoBackpack, MdPercent} from "react-icons/md";
import {FaRegClock} from "react-icons/fa";
import {Ri24HoursFill} from "react-icons/ri";

export default function Advantages() {
    return (
        <div className="advantages-section">
            <div className="advantages-cards container">
                <ul className="advantages-cards__list">
                    <li className="advantages-cards__item">
                        <div className="advantages-cards__container">
                            <div className="advantages-cards__icon">
                                <MdPercent />
                            </div>
                            <div className="advantages-cards__text">
                                <h2 className="advantages-cards__title">
                                    Низька процента ставка
                                </h2>
                            </div>
                        </div>
                    </li>
                    <li className="advantages-cards__item">
                        <div className="advantages-cards__container">
                            <div className="advantages-cards__icon">
                                <FaRegClock />
                            </div>
                            <div className="advantages-cards__text">
                                <h2 className="advantages-cards__title">
                                    Забудьте про довгі перевірки
                                </h2>
                            </div>
                        </div>
                    </li>
                    <li className="advantages-cards__item">
                        <div className="advantages-cards__container">
                            <div className="advantages-cards__icon">
                                <Ri24HoursFill />
                            </div>
                            <div className="advantages-cards__text">
                                <h2 className="advantages-cards__title">
                                    Цілодобова обробка заявок
                                </h2>
                            </div>
                        </div>
                    </li>
                    <li className="advantages-cards__item">
                        <div className="advantages-cards__container">
                            <div className="advantages-cards__icon">
                                <MdOutlineNoBackpack />
                            </div>
                            <div className="advantages-cards__text">
                                <h2 className="advantages-cards__title">
                                    Без прихованих коміссій
                                </h2>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
    );
}