import React from "react";

// Styles
import "../../styles/components/Navbar.css";


import logo from "../../assets/logo.svg"

function Navbar() {
  return (
    <div className="navbar">
      <div className="header-top">
          <div className="header-top__container container">
              <img className="header-top__image" src={logo} width="170" height="50" alt="company logo"/>

          </div>
      </div>
    </div>
  );
}

export default Navbar;