import React from "react";

import banner from "../../assets/bg.png"

//Styles
import "../../styles/components/Banner.css";

function Banner() {
    return (
        <div className="banner-section">
            <div className="banner-section__container container">
                <div className="banner-section__inner">
                    <div className="banner-section__img">
                        <img src={banner} alt="banner" width="400" height="400"/>
                    </div>
                    <div className="banner-section__content">
                        <h1 className="banner-section__title">Кредит онлайн – зручно і
                            швидко!</h1>
                        <p className="banner-section__text">Є безліч ситуацій, коли терміново можуть знадобитися гроші.
                            Вирішити фінансові труднощі
                            допоможе кредит онлайн на картку. У нас ви зможете швидко отримати до 15 000 гривень!!</p>
                        <button className="banner-section__btn button-primary">ОТРИМАТИ ГРОШІ</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;