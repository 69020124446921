import React from 'react';

// Components
import Navbar from './common/Navbar';
import HowGet from "./components/HowGet";

import Calculation from "./components/Calculation";
import Credit from "./components/Credit";
import Advantages from "./components/Advantages";
import Faq from "./components/Faq";

// Styles
import "../index.css";
import Banner from "./components/Banner";


export default function Home() {
    return (
        <div className="home">
            <Navbar />
            <Calculation />
            <HowGet />
            <Advantages />
            <Credit />
            <Banner />
            <Faq />
        </div>
    );
}